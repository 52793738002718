import React, { FC, useMemo, useState } from 'react';
import NextLink from 'next/link';
import { useParams } from 'next/navigation';
import { Variant } from 'shared/types/product';
import { Product } from 'shared/types/product/Product';
import { LineItem } from 'shared/types/wishlist/LineItem';
import Typography from 'components/commercetools-ui/atoms/typography';
import QuickView from 'components/commercetools-ui/organisms/product/product-quick-view';
import Prices from 'components/commercetools-ui/organisms/product/product-tile/prices';
import WishlistButton from 'components/commercetools-ui/organisms/wishlist/components/wishlist-button';
import ProductBadge from 'components/padi-product/badge';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { desktop } from 'helpers/utils/screensizes';
import { ProjectConfig } from 'types/project-config';
import Image from 'frontastic/lib/image';
import useTrack from './useTrack';

interface ProductTileProps {
  product: Product;
  titleHtag?: string;
  onClick?: () => void;
  isSearchResult?: boolean;
  disableQuickView?: boolean;
  disableWishlistButton?: boolean;
  disableVariants?: boolean;
  projectConfig?: ProjectConfig;
  imagePriority?: boolean;
}

const ProductTile: FC<ProductTileProps> = ({
  product,
  titleHtag,
  onClick,
  isSearchResult = false,
  disableQuickView = false,
  disableWishlistButton = false,
  disableVariants = true,
  projectConfig,
  imagePriority = false,
}) => {
  const { locale } = useParams();
  const { ref } = useTrack({ product });

  const variantWithDiscount = useVariantWithDiscount(product.variants) as Variant;

  const [selectedVariant, setSelectedVariant] = useState(() => variantWithDiscount ?? product?.variants[0]);

  const [isDesktopSizeOrLarger] = useMediaQuery(desktop);

  const hasDiscount =
    selectedVariant.discountedPrice?.centAmount &&
    selectedVariant.price?.centAmount &&
    selectedVariant.discountedPrice.centAmount < selectedVariant.price.centAmount;

  const discountedPrice = selectedVariant?.discountedPrice;

  const discountPercentage = selectedVariant
    ? (((selectedVariant.price?.centAmount as number) - (discountedPrice?.centAmount as number)) /
        (selectedVariant.price?.centAmount as number)) *
      100
    : 0;

  const [imageHovered, setImageHovered] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const buttonIsVisible = useMemo(
    () => (imageHovered || buttonHovered) && isDesktopSizeOrLarger,
    [imageHovered, buttonHovered, isDesktopSizeOrLarger],
  );

  const hideButton = () => {
    setImageHovered(false);
    setButtonHovered(false);
  };

  const productToWishlistLineItem = useMemo<LineItem | undefined>(() => {
    if (product) {
      return {
        lineItemId: product.productId ?? '',
        productId: product.productId,
        name: product.name,
        count: 1,
        variant: selectedVariant,
        addedAt: new Date(),
        _url: product._url,
      };
    }
  }, [product, selectedVariant]);

  const productUrl = useMemo(() => {
    if (!product._url) return `/${(product.name ?? '').toLowerCase().replace(/\s+/g, '-')}/p/${selectedVariant.sku}`;

    // If we use variants.
    // const name = product._url.split('/')[1];
    // return `/${name}/p/${selectedVariant.sku}` + (isSearchResult ? '?sr=1' : '');

    // This fixes /{product-type}/{slug} geting stripped.
    const url = `${product._url}` + (isSearchResult ? '?sr=1' : '');
    return url;
  }, [product, selectedVariant, isSearchResult]);

  const productType = product?.productType ?? '';
  const { formatMessage: formatMessagePadi } = useFormat({ name: 'padi' });
  const TitleHtag = titleHtag ? (titleHtag as keyof JSX.IntrinsicElements) : 'div';

  const excerptField = product.variants?.[0].attributes?.excerpt;
  const excerpt = typeof excerptField == 'string' ? `${excerptField}` : '';

  return (
    <div
      onClick={onClick}
      ref={ref}
      className="product-tile group relative flex h-full flex-col overflow-hidden rounded-none border border-gray-200 bg-white shadow-sm transition delay-200 ease-linear hover:shadow-md"
    >
      <div className="relative">
        <NextLink href={`/${locale}${productUrl}`} className="pb-16">
          <div
            className="relative w-full"
            onMouseEnter={() => setImageHovered(true)}
            onMouseLeave={() => setImageHovered(false)}
          >
            <div className="relative bg-white p-0">
              <div className="relative block h-auto w-full">
                <Image
                  src={selectedVariant.images?.[0]}
                  alt={product.name ?? ''}
                  className="aspect-video h-auto w-full object-cover object-center group-hover:opacity-75 lg:w-full"
                  priority={imagePriority}
                  style={{ objectFit: 'cover', aspectRatio: 16 / 9, width: '100%', height: 'auto' }}
                  height={192}
                  width={108}
                />
              </div>
            </div>
            <ProductBadge product={product} />
            <span
              className="absolute right-0 top-0 z-10 flex size-32 cursor-pointer items-center justify-center md:size-48"
              onClick={(e) => e.preventDefault()}
            >
              {!disableWishlistButton && productToWishlistLineItem && (
                <WishlistButton lineItem={productToWishlistLineItem} className="size-16 md:size-20 lg:size-24" />
              )}
            </span>
          </div>
        </NextLink>

        <div
          className="absolute bottom-0 z-10 w-full"
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
        >
          {/* <div className="w-full text-center">
            {hasDiscount && (
              <span className="mb-8 ml-8 flex h-[25px] w-[45px] items-center justify-center bg-accent-red text-12 text-neutral-100">
                {Math.round(discountPercentage)}%
              </span>
            )}
          </div> */}

          <div className="mb-20 rounded-sm">
            <QuickView
              buttonIsVisible={buttonIsVisible && !disableQuickView}
              hideButton={hideButton}
              product={product}
              projectConfig={projectConfig}
            />
          </div>
        </div>
      </div>

      <NextLink href={`/${locale}${productUrl}`}>
        <div className="px-8 pb-8 md:px-16 md:pb-16">
          <TitleHtag className="mt-4 block w-full whitespace-pre-line text-wrap text-lg font-bold leading-loose md:mt-12">
            {product?.name}
          </TitleHtag>
          {!disableVariants && (
            <div className="mt-8 flex items-center gap-4 text-base md:mt-12">
              {product?.variants
                .filter(
                  (variant, index, arr) =>
                    arr.findIndex((v) => v.attributes?.color === variant.attributes?.color) === index,
                )
                .map((variant, index) => (
                  <span
                    key={index}
                    className={`block cursor-pointer rounded-full border p-6 ${
                      variant.sku !== selectedVariant.sku ? 'border-neutral-300' : 'border-neutral-500'
                    }`}
                    style={{ backgroundColor: variant.attributes?.color || variant.attributes?.finish }}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedVariant(variant);
                    }}
                  ></span>
                ))}
            </div>
          )}
          <div className="mt-8 whitespace-pre-line text-padi-gray-darker md:mt-12">{excerpt}</div>
          <div className="mt-8 md:mt-12">
            {productType == 'Course' && !selectedVariant?.price ? (
              <Typography className="text-base font-bold leading-loose text-primary-black">
                {formatMessagePadi({ id: 'contactDiveShopForPrice', defaultMessage: 'Contact Dive Shop for price' })}
              </Typography>
            ) : (
              <Prices price={selectedVariant?.price} discountedPrice={discountedPrice} />
            )}
          </div>
        </div>
      </NextLink>
    </div>
  );
};

export default ProductTile;
