import { Popover } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';
import { UserIcon as UserIconSolid } from '@heroicons/react/24/solid';
import AccountDropdown from 'components/commercetools-ui/organisms/account/account-atoms/account-dropdown';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';
import { useAccount } from 'frontastic';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import { ReactElement, useEffect, useState } from 'react';
import { ProjectConfig } from 'types/project-config';

type Props = {
  projectConfig: ProjectConfig;
  userMenu: ReferenceFieldLink[];
  onIconClick?: () => void;
};

const AccountButton = (props: Props) => {
  const projectConfig = props.projectConfig;
  const userMenu = props.userMenu;

  const { loggedIn } = useAccount();
  const { userData, avatar } = useGetUserInfo();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const title = userData
    ? formatAccountMessage({ id: 'account', defaultMessage: 'Account' })
    : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Login' });

  const [avatarImage, setAvatarImage] = useState<ReactElement>(<></>);

  useEffect(() => {
    if (!loggedIn) return;
    setAvatarImage(avatar ? <img className="h-24 w-24 rounded-full object-cover" src={avatar} /> : <UserIconSolid />);
  }, [loggedIn, avatar]);

  return (
    <div className="h-40">
      <Popover as="div" className="relative h-fit">
        {({ open }) => (
          <>
            <Popover.Button title={title} onClick={props.onIconClick}>
              <div className="flex w-fit whitespace-nowrap">
                <div className="h-34 w-28 border-primary-black pb-8 hover:border-b-2  hover:border-padi-blue hover:text-padi-blue">
                  {loggedIn ? avatarImage : <UserIcon className="w-24 text-padi-gray-darkest  hover:text-padi-blue" />}
                </div>
              </div>
            </Popover.Button>
            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />
            <Popover.Panel className="absolute left-1/2 top-50 z-[310] -translate-x-1/2 animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400">
              <div className="absolute -top-20 left-1/2 z-10 w-31 -translate-x-1/2 overflow-hidden">
                <div className="h-21 w-21 origin-bottom-left rotate-45 bg-white" />
              </div>
              <AccountDropdown loggedIn={loggedIn} projectConfig={projectConfig} userMenu={userMenu} />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default AccountButton;
