import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Order } from 'shared/types/cart/Order';
import { mutate } from 'swr';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic';
import ThankYouHeader from './components/thank-you-header';
import FeedbackIconLayer from '../../../../commercetools-ui/atoms/button/feedbackIconLayer';
import usePurchase from '../../hooks/usePurchase';
import useTrackPage from '../../hooks/useTrack';

export interface BillingAddress {
  name: string;
  billingStreetAddressLine1: string;
  billingStreetAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountryCode: string;
}
export interface Payment {
  authCode: string;
  expirationMonth: string;
  expirationYear: string;
  last4: string;
  piNum: string;
  ccType: string;
  paymentType: string;
}
export interface StripeSession {
  billing: BillingAddress;
  payment: Payment;
  statusMessage: string;
}

const ThankYouContent: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<Order>();
  const { trackPage } = useTrackPage();
  const { loggedIn } = useAccount();
  const { getOrderByOrderNumber } = usePurchase();

  const searchParams = useSearchParams();
  const orderNumber = searchParams.get('orderNumber')?.trim() || '';

  const getSetCommerceToolsOrder = useCallback(async () => {
    if (!orderNumber) return;
    const orderObject: Order = await getOrderByOrderNumber(orderNumber);
    if (orderObject) {
      setOrder(orderObject);
      trackPage(orderObject);
    }
    await mutate('/action/cart/getCart');
    setIsLoading(false);
  }, [getOrderByOrderNumber, orderNumber, trackPage]);

  useEffect(() => {
    getSetCommerceToolsOrder();
  }, [getSetCommerceToolsOrder]);

  if (!loggedIn || !orderNumber) return <Redirect target="/" />;

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {isLoading ? (
        <FeedbackIconLayer loading={isLoading} variant="ghost" />
      ) : (
        <div className="mx-auto max-w-7xl px-24 lg:px-32">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            <div className="bg-white lg:rounded-md lg:pt-36">
              <ThankYouHeader email={order?.email} onPrint={handlePrint} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYouContent;
