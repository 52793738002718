import Link from 'components/commercetools-ui/atoms/link';
import { MegaMenu } from 'components/padi-ui/header/types';
import MenuCard from 'components/padi-ui/menu/menu-card';
import { usePathname } from 'next/navigation';
import React from 'react';
import { LinkReference } from 'types/reference';

export interface Props {
  megaMenus: MegaMenu[];
}

const generateClass = (megaMenu: MegaMenu, currentPath: string) => {
  const isActive = megaMenu?.reference && currentPath.includes((megaMenu.reference as LinkReference).link);
  return `inline-flex h-full items-center gap-x-1 text-base font-semibold leading-6 border-b-2 ${
    isActive
      ? 'border-padi-gray-darkest text-padi-gray-darkest'
      : 'border-white hover:border-padi-blue hover:text-padi-blue'
  }`;
};

const HeaderNavigationDesktop: React.FC<Props> = ({ megaMenus }) => {
  const currentPath = usePathname();

  return (
    <>
      {megaMenus && (
        <div className="hidden h-full w-full items-center justify-start md:pl-22 lg:flex">
          {megaMenus.map((megaMenu, index) => (
            <div key={`megaMenu-${index}`} className="group h-full">
              <div className="flex h-full cursor-pointer items-center  bg-white ">
                <div className=" mx-auto h-full max-w-7xl px-6 py-15 lg:px-8 ">
                  <div className={generateClass(megaMenu, currentPath)}>
                    {megaMenu?.reference ? (
                      <Link link={megaMenu?.reference} title={megaMenu?.label}>
                        {megaMenu?.label}
                      </Link>
                    ) : (
                      megaMenu?.label
                    )}
                  </div>
                </div>
              </div>
              <div className="invisible absolute inset-x-0 top-60 z-50 bg-white pt-16 opacity-0 shadow-lg transition-all group-hover:visible group-hover:opacity-100 group-hover:delay-500 group-hover:ease-in">
                <div className="mx-auto grid max-w-7xl grid-cols-1 px-24 py-10 lg:px-32">
                  <div className="grid w-full grid-cols-4 gap-x-12 sm:gap-x-16">
                    {megaMenu?.columns &&
                      megaMenu?.columns.map((column, index) => (
                        <div key={`column-${index}`}>
                          {column?.menus &&
                            column?.menus?.map((menu) => (
                              <div key={`menu-${index}-${menu?.title}`}>
                                <MenuCard
                                  image={menu?.image}
                                  title={menu?.title}
                                  titleUrl={menu?.titleUrl}
                                  body={menu?.body}
                                  links={menu?.links}
                                />
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default React.memo(HeaderNavigationDesktop);
