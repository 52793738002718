import { FC } from 'react';
import { useParams, usePathname, useRouter } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import HeaderTopbar from 'components/padi-ui/header/topbar';
import { ReferenceFieldLink } from 'components/padi-ui/header/types';
import { useFormat } from 'helpers/hooks/useFormat';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { deleteCognitoCookies } from 'helpers/padi/cognito';
import { desktop } from 'helpers/utils/screensizes';
import { ProjectConfig } from 'types/project-config';
import { useAccount } from 'frontastic';

export interface Props {
  showMenu: boolean;
  hideHeaderMenu: () => void;
  userMenu: ReferenceFieldLink[];
  topbarMenus?: ReferenceFieldLink[];
  projectConfig: ProjectConfig;
}

const MobileMenuFooter: FC<Props> = ({ showMenu, hideHeaderMenu, userMenu, topbarMenus, projectConfig }) => {
  const router = useRouter();
  const { account, logout } = useAccount();
  const { locale } = useParams();
  const pathname = usePathname();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [isDesktopSizeOrLarger] = useMediaQuery(desktop);
  const { userData } = useGetUserInfo();

  const handleLogout = () => {
    logout()
      .then(() => deleteCognitoCookies())
      .then(() => (pathname.endsWith('courses/') ? window.location.reload() : router.push(`/${locale}/courses`)));
    hideHeaderMenu();
  };

  const goToLoginPage = () => router.push('/login?lvp=courses');
  const goToRegisterPage = () => router.push('/login?loginPath=sign-up&lvp=courses');

  return (
    <>
      <div className="mx-24 py-16">
        {!userData && (
          <div className="mb-30">
            <div className="w-full">
              <Button variant="primary" className="w-full py-12 text-16 leading-[16px]" onClick={goToLoginPage}>
                {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </Button>
            </div>
            <div className="mt-10 w-full">
              <Button variant="secondary" className="w-full py-12 text-16 leading-[16px]" onClick={goToRegisterPage}>
                {formatAccountMessage({ id: 'create.account', defaultMessage: 'Create Account' })}
              </Button>
            </div>
          </div>
        )}

        {userMenu && userMenu.length && (
          <>
            {userMenu?.map((link, index) => (
              <div className="block w-full" key={`link-${index}-${link?.label}`}>
                <Link link={link?.url} className="mt-10 block w-fit">
                  <Typography className="text-primary-black hover:underline">{link?.label}</Typography>
                </Link>
              </div>
            ))}
          </>
        )}

        {account && userData && (
          <div onClick={handleLogout}>
            <div className="mt-10 block w-fit cursor-pointer">
              <Typography className="text-primary-black hover:underline">
                {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
              </Typography>
            </div>
          </div>
        )}
      </div>

      <div className="mx-24">
        {!isDesktopSizeOrLarger && topbarMenus?.length && <HeaderTopbar menus={topbarMenus}></HeaderTopbar>}
      </div>
    </>
  );
};

export default MobileMenuFooter;
