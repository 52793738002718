import { useState, useEffect, FC } from 'react';
import dynamic from 'next/dynamic';
import { Variant } from 'shared/types/product';
import { Product } from 'shared/types/product/Product';
import Gallery from 'components/commercetools-ui/organisms/gallery';
import { UIProduct } from 'components/commercetools-ui/organisms/product/product-details/types';
import usePath from 'helpers/hooks/usePath';
import { toUIColor } from 'helpers/mappers/toUIColor';
import { toUIProduct } from 'helpers/mappers/toUIProduct';
import { toUISize } from 'helpers/mappers/toUIsize';
import { ProjectConfig } from 'types/project-config';
import ProductDetails, { ProductDetailsProps } from '..';
import ProductDetailsCertificationCards from '../certificaton-cards';
import ProductDetailsCoursesNonPurchasable, {
  ProductDetailsCoursesNonPurchasableProps,
} from '../courses-non-purchasable';
// import ProductDetailsSubscription from '../subscription';

const DynamicProductDetailsSubscription = dynamic(() => import('../subscription'));

type ProductDetailsAdapterProps = {
  product: Product;
  inModalVersion?: ProductDetailsProps['inModalVersion'] | ProductDetailsCoursesNonPurchasableProps['inModalVersion'];
  setIsOpen?: (value: boolean) => void;
  onAddToCart?: () => void;
  projectConfig: ProjectConfig;
};

const ProductDetailsAdapter: FC<ProductDetailsAdapterProps> = ({
  product,
  inModalVersion,
  setIsOpen,
  onAddToCart,
  projectConfig,
}) => {
  const { path } = usePath();
  const [variant, setVariant] = useState<Variant>();
  const [mappedProduct, setMappedProduct] = useState<UIProduct>();
  const categories = product?.categories;
  const productType = product?.productType;
  const ProductDetailsSubscription = DynamicProductDetailsSubscription;

  useEffect(() => {
    if (product && variant) {
      const colors = toUIColor(product);
      const sizes = toUISize(product);
      const productToUse = toUIProduct(product, variant, colors, sizes);
      setMappedProduct({ ...productToUse, images: [productToUse.images[0]] });
    }
  }, [product, variant]);

  useEffect(() => {
    if (!product) return;

    if (inModalVersion) {
      setVariant(product?.variants[0]);
    } else {
      const currentVariantPath = path.split('/');
      const currentVariantSKU = currentVariantPath[3]?.split('?')[0];
      const currentVariantIndex = product?.variants.findIndex(({ sku }) => sku == currentVariantSKU);
      setVariant(product.variants[currentVariantIndex] ?? product.variants?.[0]);
    }
  }, [inModalVersion, product, path]);

  const handleChangeVariant = (sku: string) => {
    const variantsToUse = product.variants.find((variant) => variant.sku === sku);
    setVariant(variantsToUse);
  };

  if (!product || !variant)
    // Fill above the fold to reduce LCP druing page load.
    return (
      <div className="mx-auto max-w-7xl px-24 pb-32 pt-20 md:grid md:grid-cols-12 md:items-start md:pt-24 lg:px-32">
        <div className="md:col-span-7 md:pr-26 lg:col-span-8 lg:pr-60">
          <Gallery images={[]} />
        </div>

        <div className="mt-24 md:col-span-5 md:mt-0 lg:col-span-4"></div>
      </div>
    );

  if (categories) {
    for (let i = 0; i < categories?.length; i++) {
      // Non-purchasable courses.
      if (categories[i].slug == 'not-purchasable-online') {
        return (
          <ProductDetailsCoursesNonPurchasable
            product={mappedProduct as UIProduct}
            variant={variant}
            url={product._url}
            inModalVersion={inModalVersion}
            onChangeVariant={handleChangeVariant}
            setIsOpen={setIsOpen}
            onAddToCart={onAddToCart}
            projectConfig={projectConfig}
          />
        );
      }

      // Cert cards.
      if (categories[i].slug == 'certification-cards') {
        return (
          <ProductDetailsCertificationCards
            product={mappedProduct as UIProduct}
            variant={variant}
            url={product._url}
            inModalVersion={inModalVersion}
            onChangeVariant={handleChangeVariant}
            setIsOpen={setIsOpen}
            onAddToCart={onAddToCart}
            projectConfig={projectConfig}
          />
        );
      }
    }
  }

  // Subscription
  if (productType === 'subscription') {
    return (
      <ProductDetailsSubscription
        product={mappedProduct as UIProduct}
        variant={variant}
        url={product._url}
        inModalVersion={inModalVersion}
        onChangeVariant={handleChangeVariant}
        setIsOpen={setIsOpen}
        onAddToCart={onAddToCart}
      />
    );
  }

  // Default.
  return (
    <ProductDetails
      product={mappedProduct as UIProduct}
      variant={variant}
      url={product._url}
      inModalVersion={inModalVersion}
      onChangeVariant={handleChangeVariant}
      setIsOpen={setIsOpen}
      onAddToCart={onAddToCart}
    />
  );
};

export default ProductDetailsAdapter;
