import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import PadiDiveShopStorage from 'components/padi-dive-shop/dive-shop-storage';
import HeaderLogo from 'components/padi-ui/header/header-logo';
import HeaderNavigationDesktop from 'components/padi-ui/header/header-navigation/header-navigation-desktop';
import HeaderNavigationMobile from 'components/padi-ui/header/header-navigation/header-navigation-mobile';
import { PadiHeaderProps, ReferenceFieldLink } from 'components/padi-ui/header/types';
import UtilitySection from 'components/padi-ui/header/utility-section';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { switchToPadiNonProdDomain } from 'helpers/padi/domains';
import { isNonProd } from 'helpers/utils/environment';
import { desktop } from 'helpers/utils/screensizes';
import { useAccount } from 'frontastic';
import HeaderTopbar from './topbar';

const CommercetoolsSearch = dynamic(() => import('components/commercetools-ui/atoms/search'));

const PadiHeader: React.FC<PadiHeaderProps> = ({
  topbarMenus,
  megaMenus,
  anonMenulinks,
  studentMenulinks,
  proMenulinks,
  categories,
  logo,
  logoLink,
  emptyCartTitle,
  emptyCartSubtitle,
  emptyCartImage,
  emptyCartCategories,
  emptyWishlistTitle,
  emptyWishlistSubtitle,
  emptyWishlistImage,
  emptyWishlistCategories,
  searchParams,
  projectConfig,
  isDisplayMainNavigation = true,
  isDisplayCartIcon = true,
  isDisplayProfileIcon = true,
  isDisplaySearchIcon = true,
  isDisplayTopBar = true,
  isDisplayWishlistIcon = true,
  isDisplayHamburgerMenu = true,
}) => {
  const Search = CommercetoolsSearch;
  const padiDomains = projectConfig?.padiDomains;
  const { loggedIn } = useAccount();
  const { userData, memberType } = useGetUserInfo();
  const [userMenu, setUserMenu] = useState<ReferenceFieldLink[]>(anonMenulinks);
  const [isDesktopSizeOrLarger] = useMediaQuery(desktop);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false);

  const toggleSearchBar = useCallback((forceState?: boolean) => {
    setIsSearchBarOpen((prev) => (typeof forceState === 'boolean' ? forceState : !prev));
  }, []);

  useEffect(() => {
    const nonProdLinks: ReferenceFieldLink[] = [];

    if (loggedIn && userData) {
      if (memberType) {
        switch (memberType) {
          case 'pro':
            if (isNonProd() && padiDomains) {
              proMenulinks?.map((link) => {
                if (link?.url.type == 'link') {
                  const nonprodurl = switchToPadiNonProdDomain(padiDomains, link?.url?.link);
                  nonProdLinks.push({
                    label: link.label,
                    url: {
                      link: nonprodurl,
                      type: link.url.type,
                      openInNewWindow: link.url.openInNewWindow,
                    },
                  });
                }
              });
            }
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
          case 'student':
            if (isNonProd() && padiDomains) {
              studentMenulinks?.map((link) => {
                if (link?.url.type == 'link') {
                  const nonprodurl = switchToPadiNonProdDomain(padiDomains, link?.url?.link);
                  nonProdLinks.push({
                    label: link.label,
                    url: {
                      link: nonprodurl,
                      type: link.url.type,
                      openInNewWindow: link.url.openInNewWindow,
                    },
                  });
                }
              });
            }
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
          default:
            setUserMenu(nonProdLinks.length ? nonProdLinks : studentMenulinks);
            break;
        }
      }
    } else {
      if (isNonProd() && padiDomains) {
        anonMenulinks.map((link) => {
          if (link?.url?.type == 'link') {
            const nonprodurl = switchToPadiNonProdDomain(padiDomains, link?.url?.link);
            nonProdLinks.push({
              label: link.label,
              url: {
                link: nonprodurl,
                type: link.url.type,
                openInNewWindow: link.url.openInNewWindow,
              },
            });
          }
        });
        setUserMenu(nonProdLinks.length ? nonProdLinks : anonMenulinks);
      }
    }
  }, [anonMenulinks, loggedIn, memberType, padiDomains, proMenulinks, studentMenulinks, userData]);

  return (
    <>
      <PadiDiveShopStorage urlParams={searchParams as Record<string, string>}></PadiDiveShopStorage>
      {isDisplayTopBar && isDesktopSizeOrLarger && topbarMenus?.length && (
        <HeaderTopbar menus={topbarMenus}></HeaderTopbar>
      )}
      {isDisplayMainNavigation && (
        <header className="relative w-full bg-white">
          <div
            aria-label="Top"
            className="mx-auto flex h-full max-w-7xl items-center justify-between px-16 sm:px-24 lg:px-32"
          >
            {isDisplayHamburgerMenu && (
              <HeaderNavigationMobile
                logo={logo}
                logoLink={logoLink}
                megaMenus={megaMenus}
                userMenu={userMenu}
                topbarMenus={topbarMenus}
                projectConfig={projectConfig}
              />
            )}

            <div className="flex size-full h-52 items-center justify-start lg:h-64">
              <HeaderLogo
                logo={logo}
                logoLink={logoLink}
                imageClassName="flex h-full w-82 md:w-88 lg:w-116 justify-start text-16 font-bold md:text-28"
              />
              <HeaderNavigationDesktop megaMenus={megaMenus} />
            </div>

            <div className="flex justify-end xl:w-fit">
              <UtilitySection
                emptyCartTitle={emptyCartTitle}
                emptyCartSubtitle={emptyCartSubtitle}
                emptyCartImage={emptyCartImage}
                emptyCartCategories={emptyCartCategories}
                emptyWishlistTitle={emptyWishlistTitle}
                emptyWishlistSubtitle={emptyWishlistSubtitle}
                emptyWishlistImage={emptyWishlistImage}
                emptyWishlistCategories={emptyWishlistCategories}
                userMenu={userMenu}
                projectConfig={projectConfig}
                handleClickOnSearchIcon={toggleSearchBar}
                isSearchBarOpen={isSearchBarOpen}
                isDisplayCartIcon={isDisplayCartIcon}
                isDisplayProfileIcon={isDisplayProfileIcon}
                isDisplaySearchIcon={isDisplaySearchIcon}
                isDisplayWishlistIcon={isDisplayWishlistIcon}
              />
            </div>
          </div>
          {
            /* Search bar */
            isSearchBarOpen && (
              <div
                id="store-header-search"
                className="relative mx-auto flex h-70 w-full max-w-7xl items-center justify-between bg-white px-24 lg:px-32"
              >
                <div className="w-full py-12 lg:absolute lg:right-32 lg:top-0 lg:box-content lg:w-[33%] ">
                  <Search categories={categories} toggleSearchBar={toggleSearchBar} />
                </div>
              </div>
            )
          }
        </header>
      )}
    </>
  );
};

export default React.memo(PadiHeader);
